@import '../../partials/colors';

.account-card {
    padding: 2rem 3rem 3rem 3rem;
}

.dmti-card-header {
    font-weight: 200;
}

.account-info-label {
    margin: 0;
    font-size: .85em;
    color: #676767;
}

.account-current-avatar {
    width: 20rem;
    max-width: 100%;
    margin: 10px 0 1rem 0;
}

.account-info-group {
    padding-bottom: 2rem;
}

.account-card-header {
    color: $text-dark;
    font-family: kandal, sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 1.5rem;
}

.reset-button {
    display: inline-block;

    &:hover {
        text-decoration: none;
    }
}

//576px and smaller
@media (max-width: 576px) {
    .account-card {
        padding: 2rem 1.5rem 3rem 1.5rem;
    }
}