@import '../../../partials/colors';

.uploaded-image {
    max-width: 100%;
}
label {
    font-family: acumin-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}
.modal-title {
    font-family: kandal;
    font-size: 1.5em;
    font-weight: 500;
    margin: 0;
    color: $text-dark;
}