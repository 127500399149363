.childList-trash-icon {
    &:hover {
        cursor: pointer;
    }
}

// Fixes a weird hover issue causing the top line on child list to disappear
.list-group-item:hover {
    z-index: 0;
}

@media (max-width: 576px) {
    .list-group-item {
        span {
            font-size: .75em;
        }
    }
}