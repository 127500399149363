.slide-header {
    font-weight: 700;
    color: #2D5368;
    font-size: 2em;
}

.dmti-section-title {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 3em;
    color: #676767;
}

.cover-subtext {
    font-size: 4em;
    font-family: futura-pt-bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #ababab;
}

.instruction-text {
    font-size: 2em;
    color: #FFFFFF;
    font-family: acumin-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 5rem;
}

.question-text {
    font-size: 4em;
    color: #565656;
    font-family: report, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#answerDropdownMenuButton {
    font-size: 1.5em
}

.answer-drop-label {
    color: #FFF;
    font-size: 1.3em;
    font-weight: 500;
    font-family: acumin-pro, sans-serif;
    font-style: normal;
}

.answer-dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.answer-check {
    font-size: 1.3em;
    color: #058057;
}

.slide-column {
    min-height: 30rem;
}

.screener-drop.menu {
    min-width: 13rem !important;
}
.dropdown-toggle::after {
    vertical-align: 0.15em;
}