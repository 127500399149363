@import '../../partials/colors';

.card {
    border-radius: 1rem !important;
}

.section-title {
    font-size: 1.75em;
    color: $text-dark;
    margin-bottom: 1rem;
    font-family: kandal, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.show-all-link {
    color: #007bFF;
    position: absolute;
    top: 30px;
    right: 25px;
    &:hover {
        cursor: pointer;
        color: #0056b3;
    }
}