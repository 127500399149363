

.avatar {
    border-radius: 50%;
    &.sm {
        max-width: 2rem;
        max-height: 2rem;
        height: 2rem;
        width: 2rem;
    }
    &.md {
        max-width: 4rem;
        max-height: 4rem;
        height: 4rem;
        width: 4rem;
    }
    &.lg {
        max-width: 6rem;
        max-height: 6rem;
        height: 6rem;
        width: 6rem;
    }
    &.xl {
        max-width: 8rem;
        max-height: 8rem;
        height: 8rem;
        width: 8rem;
    }
}