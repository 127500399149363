
$primary-color : #00A99D;

$text-dark: #044043;
$text-grey: #67817F;
$text-light-green: #9CCECA;

$accent-pink: #E3569F;
$accent-yellow: #FDCB5F;
$accent-blue: #2851A3;

$alert-danger: #F9CDE0;
$alert-caution: #FFD28B;
$alert-info: #BACAE9;
$alert-secondary: #E1DDE7;