@import '../../../partials/colors';

.child-result {
    padding: 1rem 0 2rem 0;
}
.result-dimension-label {
    font-size: .8em;
    color: #343434;
    margin: 5px 0 0 0;
    white-space: nowrap;
}
.score-badge {
    color: #FFF;
    background-color: $text-grey;
    margin: 0 5px;
    padding: 6px;
}
.next-steps {
    margin: 2rem 0 .5rem 0;
    font-size: 1.5em;
}
.next-text {
    font-size: 1.1em;
    color: $text-grey;
    margin-bottom: 2rem;
}
p {
    font-family: acumin-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: $text-dark;
}
h1, h2, h3 {
    font-family: kandal, serif;
    font-weight: 400;
    font-style: normal;
    color: $text-dark;
}

// 992px and smaller
@media (max-width: 992px) {
    .result-dimension-label {
        margin: 5px 0 1.25rem 0;
    }
}

