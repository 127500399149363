.resource-card {
    background-color: #FFF;
    border: 1px solid #CCCCCC;
    padding: 15px;
    height: 100%;
}
.card-hdr {
    display: flex;
    align-items: center;
}

.resource-title {
    font-family: sans-serif;
    font-size: 1.1em;
    color: #606060;
}

.desc {
    font-family: sans-serif;
    color: #808080;
    font-size: .75em;
    margin-top: 5px;
}

.link {
    color: #3894FF;
    font-family: acumin-pro, sans-serif;
    font-weight: 400;
    font-style: normal;

    &.resource-link {
        font-size: .85em;
    }
}

.link:hover {
    color: #0056b3;
    text-decoration: none;
}

.author {
    font-family: sans-serif;
    font-weight: bold;
    color: #606060;
    font-size: .75em;
    margin-top: 10px;
    margin-bottom: 0;
}

.icon {
    max-width: 3rem;
}

.icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DMTI-resource {
    max-width: 4rem;
    margin-top: 10px;
}