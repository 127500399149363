@import '../../partials/colors';

.grade-btn {
    background-color: #FFF;
    border: 1px solid $primary-color;
    color: $primary-color;
    padding-bottom: 10px;

    &:hover {
        cursor: pointer;
    }
    &.active {
        background-color: $primary-color;
        color: #FFF;
    }
    &.focus {
        box-shadow: none;
        outline: none;
    }
}

.dimension-name {
    font-family: kandal, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: $text-dark;
    margin: 3rem 0 2rem 0;
    font-size: 2em;
}

.resource-type {
    font-family: kandal, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: $text-dark;
    margin: 2rem 0 1rem 0;
    font-size: 1.5em;
}