.main-content {
    padding-top: 7rem;
    padding-bottom: 6rem;
}

.page-header {
    font-size: 3em;
    font-family: acumin-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    padding: 0 0 2rem 0;
    margin: 0;
}

