.score-color-block {
    border-radius: 5px;
    height: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        font-weight: 700;
    }
}