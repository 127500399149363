@import '../../partials/colors';

.childtile-grade {
    color: $text-grey;
    font-weight: 400;
}
.child-name {
    font-family: kandal, serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.75em;
    color: $text-dark;
}
.child-card {
    padding: 3.5rem 4rem;
    margin-bottom: 3rem;
}

@media (max-width: 576px) {
    .child-card {
        padding: 3rem 1.5rem;
    }
}