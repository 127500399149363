@import '../../partials/colors';


.loading-spin {
    font-size: 3em;
    color: $primary-color;
}
.page-header {
    font-family: cubano, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: $primary-color;
}

.new-user-alert {
    border-radius: 25px;
    background-color: #d4edff;
    border: 4px solid #4fa6ff;
    padding: 1rem 3rem;
    text-align: center;
    margin-bottom: 2rem;

    >h1 {
        font-family: cubano, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    >p {
        font-weight: 400;
    }
}

.math-pack-tile {
    padding: 3rem;
    border-top: 100px solid #869FC5;

    .negative-margin {
        margin-top: -136px;
    }
    .math-pack-characters {
        width: 30%;
        max-width: 100%;
    }
    .mp-header {
        margin-top: 3rem;
    }
    span {
        font-family: cubano, sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #FFF;
        font-size: 3em;
    }
    .mp-banner {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    >.card-body {
        padding: 0 1.25rem 1.25rem 1.25rem;
    }
}

