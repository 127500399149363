
.loading {
    height: 5px;
    width: 100%;
    background-color: #EFEFEF;
    overflow: hidden;
    border-radius: 3px;
}
.loading.tall {
    height: 15px !important;
}
.ld.loading-inner {
    position: relative;
    animation-name: load;
    height: 100%;
    background-color: #2D5368;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
}
@keyframes animatedBackground {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}
@keyframes load {
    0%   { left: 0; width: 0; }
    50%  { left: 0; width: 100%; }
    100% { left: 100%; width: 0; }
}