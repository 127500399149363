@import '../../partials/colors';

.signup-block {
    position: absolute;
    top: 25px;
    right: 50px;
}
.login-card {
    padding: 3rem;
}
.reset-link {
    float: right;
}
.login-logo {
    max-width: 100%;
    margin-bottom: 1rem;
}
.brand-text {
    color: $primary-color;
    font-weight: 700;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 3rem;
}

//576px and smaller
@media (max-width: 576px) {
    .login-card {
        padding: 3rem 1.5rem;
    }
    .signup-block {
        position: relative;
        right: 0px;
    }
}