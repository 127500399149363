@import '../../partials/colors';

.activity-tile {
    background-color: #FFF;
    border: 1px solid $primary-color;
    border-top-width: 40px;
    border-radius: 7px;
    padding: .5rem 1rem 1rem 1rem;
}
.activity-hdr-wrap {
    display: flex;
    position: absolute;
    top: 0;
    height: 40px;
    align-items: center;
    color: #FFF;

    >h4 {
        margin: 0 0 3px 10px;
        font-family: cubano, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
}
.dmti-logo-activity {
    max-width: 2rem;
}
.activity-name {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.1em;
}
.activity-grade {
    font-family: acumin-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #67817F;
    margin-bottom: .5rem;
    font-size: .85em;
    line-height: 1;
}
.activity-btn {
    border: 1px solid $primary-color;
    line-height: 1;
    color: $primary-color;
    padding: 5px 10px;
    font-size: .8em;
    border-radius: 30px;
    transition: all .2s ease;

    &:hover {
        background-color: $primary-color;
        color: #FFF;
    }
}