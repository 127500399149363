@import '../../partials/colors';


.header-link, .header-link:hover {
    color: #FFF;
    text-decoration: none;
    margin-right: 1rem;
    background-color: transparent;
    border: none;
}
.header-link:hover {
    cursor: pointer;
}
.cc-nav {
    background-color: $primary-color;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 3px 5px rgba(0,0,0,.2);
    z-index: 100;
    padding: 5px 0;
    height: 60px;
}
.cc-nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.cc-nav-icon {
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: none;
    }
}
.cc-nav-icon img {
    max-width: 36px;
    margin: 0 6px 0 10px;
    padding-top: 4px;
}
.dmti {
    font-family: cubano, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #FFF;
    font-size: 2em;
}
.p-portal {
    font-family: kandal;
    color: white;
    font-size: 1.75em;
    font-weight: 500;
    margin-left: 15px;
    padding-top: 5px;
    white-space: nowrap;
}
.cc-nav-title {
    margin: 0;
    font-size: 1.75em;
    font-weight: 700;
    display: inline-block;
    color: #FFF;
}
.cc-nav-menu-toggle {
    margin: 0 1rem 0 0;
    color: #FFF;
}
.cc-nav-menu-toggle svg {
    font-size: 1.3em !important;
    vertical-align: middle;
}
.cc-nav-menu {
    height: 100vh;
    width: 250px;
    background-color: white;
    box-shadow: -3px 3px 5px rgba(0,0,0,.2);
    position: fixed;
    right: -250px;
    top: 60px;
    transition: .2s ease all;
    z-index: 101;
    border-top: 1px solid lightgray;
}
.cc-nav-menu ul {
    margin: 0;
    padding: 0;
}
.home-link {
    height: 4rem !important;
    border-bottom: 1px solid #E4E4E4;
}
.home-link h3 {
    color: $primary-color;
}
.cc-nav-menu li {
    height: 3rem;
    display: flex;
    align-items: center;
}
.cc-nav-menu li:hover {
    background-color: #F7F7F7;
    cursor: pointer;
}
.cc-nav-menu li a {
    text-decoration: none;
    color: $primary-color;
    font-weight: 400;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 20px;
    font-family: acumin-pro, sans-serif;
}

.cc-nav-menu li.active {
    background-color: #EEFBFA;
}
.cc-nav-menu li.active a {
    color: $primary-color;
}
.cc-nav-menu ul, .cc-nav-menu li {
    list-style: none;
}
.cc-nav-menu.open {
    right: 0;
}
.cc-nav-menu-toggle:hover {
    cursor: pointer;
}
.cc-account-toggle {
    background-color: transparent;
    border: none;
    &:focus {
        outline: none;
    }
}
.cc-account-toggle:hover {
    cursor: pointer;
}
.cc-dropdown {
    max-height: 0;
    width: 200px;
    overflow: hidden;
    position: absolute;
    right: 40px;
    background-color: #FFF;
    top: 40px;
    margin: 0;
    padding: 0;
    box-shadow: 0 5px 9px rgba(0,0,0,.2);
    transition: max-height .5s ease;
}
.cc-dropdown.show {
    max-height: 10rem;
}
.cc-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.cc-dropdown ul li {
    display: flex;
    align-items: center;
}
.cc-dropdown ul li a, .cc-dropdown ul li button {
    padding: 10px 20px;
    text-decoration: none;
    color: $primary-color;
    font-family: acumin-pro, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    width: 100%;
    text-align: left;
}
.cc-dropdown ul li button:hover {
    color: $primary-color;
}
.cc-dropdown ul li:hover {
    background-color: #F7F7F7;
    cursor: pointer;
}

@media (max-width: 876px) {
    .cc-nav-title {
        display: none;
    }
}

// Small devices
@media (max-width: 576px) {
    .p-portal {
        font-size: 1.4em;
        margin-left: 5px;
    }
}


/* not needed if not using cc-container on page */
nav ~ .cc-container {
    margin-top: 5rem;
}

.navMenu-DMTI-logo {
    height: 100%;
    padding: 7px;
}
.navMenu-dmti {
    font-family: cubano, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5em;
}
#home a {
    justify-content: center;
}


